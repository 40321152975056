<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Register v1  -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            REGISTER
          </h2>
        </b-link>

        <b-card-title class="mb-1" />
        <b-card-text class="mb-2" />

        <!-- form -->
        <b-form
            class="auth-register-form mt-2"
            @submit.prevent="signupOne"
        >

          <!-- userId -->
          <b-form-group
              label="ID"
              label-for="userId"
          >
            <b-form-input
                id="userId"
                v-model="registData.userid"
                name="register-userId"
                placeholder="Enter your ID (e.g., bird123)"
                @blur="handleBlurUserid"
                @input="convertToLowercase"
                maxlength="10"
            />
            <small
                v-if="userIdError"
                class="text-danger"
            >{{ userIdError }}</small>
          </b-form-group>
          <!-- password -->
          <b-form-group
              label="Password"
              label-for="password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                  id="password"
                  v-model="registData.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="register-password"
                  placeholder="Enter your password"
                  @blur="validatePassword"
                  maxlength="10"
              />
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small
                v-if="passwordError"
                class="text-danger"
            >{{ passwordError }}</small>
          </b-form-group>

          <!-- nickname -->
          <b-form-group
              label="닉네임"
              label-for="nickname"
          >
            <b-form-input
                id="nickname"
                v-model="registData.nickname"
                name="register-nickname"
                placeholder="Choose a nickname"
                @blur="handleBlurNickname"
                maxlength="10"
            />
            <small
                v-if="nicknameError"
                class="text-danger"
            >{{ nicknameError }}</small>
          </b-form-group>

          <!-- email -->
          <b-form-group
              label="Email"
              label-for="email"
          >
            <b-form-input
                id="email"
                v-model="registData.email"
                name="register-email"
                placeholder="Enter your email (e.g., bird123@example.com)"
                @blur="handleBlurUserEmail"
                maxlength="25"
            />
            <small
                v-if="emailError"
                class="text-danger"
            >{{ emailError }}</small>
          </b-form-group>

          <!-- username -->
          <b-form-group
              label="Your Name"
              label-for="userName"
          >
            <b-form-input
                id="userName"
                v-model="registData.username"
                name="register-userName"
                placeholder="Enter your full name"
                maxlength="10"
            />
          </b-form-group>

          <!-- mobile -->
          <b-form-group
              label="Mobile"
              label-for="mobile"
          >
            <b-form-input
                id="mobile"
                v-model="registData.mobile"
                name="register-mobile"
                placeholder="Enter your mobile number (e.g., 0101234567)"
                maxlength="20"
                @input="handleMobileInput"
            />
            <small
                v-if="mobileError"
                class="text-danger"
            >{{ mobileError }}</small>
          </b-form-group>

          <!-- accountBank -->
          <b-form-group
              label="Account Bank"
              label-for="accountBank"
          >
            <b-form-input
                id="accountBank"
                v-model="registData.accountBank"
                name="register-accountBank"
                placeholder="Enter your your bank"
                maxlength="20"
            />
          </b-form-group>

          <!-- accountNumber -->
          <b-form-group
              label="Account Number"
              label-for="accountNumber"
          >
            <b-form-input
                id="accountNumber"
                v-model="registData.accountNumber"
                name="register-accountNumber"
                placeholder="Enter your account number (e.g., 12012341234)"
                maxlength="20"
                @input="handleAccountNumberInput"
            />
            <small
                v-if="accountNumberError"
                class="text-danger"
            >{{ accountNumberError }}</small>
          </b-form-group>

          <!-- outcomePassword -->
          <b-form-group
              label="Outcome Password"
              label-for="outcomePassword"
          >
            <b-input-group
                class="input-group-merge"
            >
              <b-form-input
                  id="outcomePassword"
                  v-model="registData.outcomePassword"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="register-outcomePassword"
                  placeholder="Set your outcome password"
                  maxlength="10"
              />
              <b-input-group-append is-text>
                <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <!-- refer code -->
          <b-form-group
              label="추천코드"
              label-for="recommendCode"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                  id="recommendCode"
                  v-model="registData.recommendCode"
                  name="register-recommendCode"
                  placeholder="Enter your referral code (Optional)"
                  maxlength="20"
              />
              <b-button
                  v-if="false"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="isValidRecommendCode ? 'primary' : 'secondary'"
                  style="margin-left: 1rem; min-width: 5rem;"
                  :disabled="!isValidRecommendCode"
                  @click="handleValid('recommendCode')"
              >
                확인
              </b-button>
            </div>
          </b-form-group>

          <!-- submit button -->
          <b-button
              variant="primary"
              block
              type="submit"
          >
            Sign up
          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import axios from 'axios'
import _ from 'lodash'

import {CHECK_EMAIL_INFO, CHECK_SIGNUP_PROHIBITS_INFO, CHECK_USER_INFO, SIGNUP_ONE} from '@/store/auth/action'
import { createNamespacedHelpers } from 'vuex'

const authStore = createNamespacedHelpers('authStore')

export default {
  name: 'Signup',

  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      registData: {
        site: null,
        userid: null,
        password: null,
        nickname: null,
        email: null,
        recommendCode: null,
        enrollIp: null,
        username: null,
        mobile: null,
        accountBank: null,
        accountNumber: null,
        outcomePassword: null,
      },
      userIdError: '',
      emailError: '',
      passwordError: '',
      mobileError: '',
      accountNumberError: '',
      nicknameError: '',
      warningMessage: '',
    }
  },
  computed: {
    ...authStore.mapGetters({
      chkUserInfo: 'chkUserInfo',
      chkEmailInfo: 'chkEmailInfo',
      chkSignupProhibitsInfo: 'chkSignupProhibitsInfo',
    }),
    isValidRecommendCode() {
      return this.registData.recommendCode && this.registData.recommendCode.trim().length >= 4
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.registData.site = this.$site
    this.registData.enrollIp = localStorage.getItem('ip')
  },
  methods: {
    ...authStore.mapActions({
      $signupOne: SIGNUP_ONE,
      $chkUserInfo: CHECK_USER_INFO,
      $chkEmailInfo: CHECK_EMAIL_INFO,
      $chkSignupProhibitsInfo: CHECK_SIGNUP_PROHIBITS_INFO,
    }),
    signupOne() {
      // 모든 에러 상태를 배열로 정의
      const errors = [this.userIdError, this.emailError, this.passwordError, this.mobileError, this.accountNumberError, this.nicknameError]

      // 필수 필드 목록을 배열로 정의
      const requiredFields = [
        this.registData.userid,
        this.registData.password,
        this.registData.nickname,
        this.registData.email,
        this.registData.username,
        this.registData.mobile,
        this.registData.accountBank,
        this.registData.accountNumber,
        this.registData.outcomePassword,
      ]

      // 필수 필드 중 하나라도 빈 값('')이면 return
      if (requiredFields.some(field => field === '' || field === null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '추천 코드를 제외한 정보는 입력해야 합니다.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        return
      }

      // 에러가 모두 빈 값('')일 때만 submit 함수 호출
      if (errors.every(error => error === '')) {
        this.submit()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '올바른 정보를 입력해 주세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
    submit() {
      Swal.fire({
        title: '가입 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes()
        }
      })
    },

    async confirmYes() {
      const cleanedData = { ...this.registData }

      // 모든 필드에서 공백 제거
      Object.keys(cleanedData).forEach(key => {
        if (typeof cleanedData[key] === 'string') {
          cleanedData[key] = cleanedData[key].replace(/\s+/g, '')
        }
      })

      await this.$signupOne({
        ...cleanedData,
      })
          .then(res => {
            switch (res) {
              case 1:
                Swal.fire({
                  title: '완료 되었습니다.',
                  icon: 'success',
                  timer: 5000,
                  background: 'rgb(3,11,37)',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                }).then(() => {
                  this.reset()
                  this.$router.push({ name: 'login' })
                })
                break
              case 0:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '이미 등록된 정보가 있습니다. ',
                    icon: 'EditIcon',
                    variant: 'error',
                  },
                })
                break
              case -99:
              default:
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '등록실패!! (관리자에게 문의바랍니다.)',
                    icon: 'EditIcon',
                    variant: 'error',
                  },
                })
                break
            }
          })
    },

    async handleValid(data) {
      console.log(data, this.registData[data])
    },
    reset() {
      this.registData.userid = null
      this.registData.password = null
      this.registData.nickname = null
      this.registData.email = null
      this.registData.recommendCode = null
      this.userid = null
      this.username = null
      this.mobile = null
      this.accountBank = null
      this.accountNumber = null
      this.outcomePassword = null
    },

    async handleBlurUserid() {
      // ID 유효성 검사
      if (!this.validateUserId()) {
        return
      }

      const { userid } = this.registData

      try {
        // 서버에 ID 중복 여부 체크 요청
        await this.$chkUserInfo({ userid })

        if (this.chkUserInfo) {
          this.userIdError = ''
        } else {
          this.userIdError = '사용할 수 없는 ID 입니다.'
        }
      } catch (error) {
        this.userIdError = '관리자에게 문의 바랍니다.'
      }
    },

    validateUserId() {
      const inputValue = this.registData.userid?.trim() || ''

      if (!inputValue) {
        this.userIdError = 'ID를 입력해주세요.'
        return false
      }

      // 영문자와 숫자만 허용하는 정규식
      const allowedChars = /^[a-zA-Z0-9]*$/

      if (!allowedChars.test(inputValue)) {
        this.userIdError = 'ID는 영문자와 숫자만 입력 가능합니다.'
        return false
      }

      // 길이 체크: 4자 미만일 경우
      if (inputValue.length < 4) {
        this.userIdError = 'ID는 최소 4자 이상이어야 합니다.'
        return false
      }

      // 모든 조건을 통과하면 에러 메시지를 비움
      this.userIdError = ''
      return true
    },

    async handleBlurUserEmail() {
      if (!this.validateEmail()) {
        return
      }

      const { email } = this.registData

      try {
        // 서버에 이메일 중복 여부 체크 요청
        await this.$chkEmailInfo({ email })

        if (this.chkEmailInfo) {
          this.emailError = ''
        } else {
          this.emailError = '사용할 수 없는 email 입니다.'
        }
      } catch (error) {
        this.emailError = '관리자에게 문의 바랍니다.'
      }
    },

    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const emailValue = this.registData.email?.trim() || ''

      // 이메일이 입력되지 않은 경우
      if (!emailValue) {
        this.emailError = 'email을 입력해주세요.'
        return false
      }

      // 이메일이 올바른 형식인지 확인
      if (!emailPattern.test(emailValue)) {
        this.emailError = '올바른 이메일 형식이 아닙니다.'
        return false
      }

      // 모든 조건을 통과하면 에러 메시지를 비움
      this.emailError = ''
      return true
    },

    async handleBlurNickname() {
      if (!this.validateNickname()) {
        return
      }
      const { nickname } = this.registData
      try {
        // 서버에 이메일 중복 여부 체크 요청
        await this.$chkSignupProhibitsInfo({
          site: this.registData.site,
          nickname,
        })

        if (this.chkSignupProhibitsInfo) {
          this.nicknameError = ''
        } else {
          this.nicknameError = '사용할 수 없는 닉네임 입니다.'
        }
      } catch (error) {
        this.nicknameError = '관리자에게 문의 바랍니다.'
      }
    },

    validateNickname() {
      const nicknameValue = this.registData.nickname?.trim() || ''

      // 이메일이 입력되지 않은 경우
      if (!nicknameValue) {
        this.nicknameError = '닉네임을 입력해주세요.'
        return false
      }

      // 모든 조건을 통과하면 에러 메시지를 비움
      this.nicknameError = ''
      return true
    },

    validatePassword() {
      const { password } = this.registData

      if (!password || password === null) {
        return false
      }

      if (password.length < 4) {
        this.passwordError = '비밀번호는 최소 4자 이상이어야 합니다.'
      } else {
        this.passwordError = ''
      }
    },

    handleMobileInput() {
      const { mobile } = this.registData

      // 모바일 번호가 입력되지 않은 경우
      if (!mobile) {
        this.mobileError = '모바일 번호를 입력해주세요.'
      } else if (/\D/.test(mobile)) {
        this.mobileError = '모바일 번호는 숫자만 포함해야 합니다.'
      } else {
        this.mobileError = ''
      }
    },

    handleAccountNumberInput() {
      const { accountNumber } = this.registData
      if (!accountNumber) {
        this.accountNumberError = '모바일 번호를 입력해주세요.'
      } else if (/\D/.test(accountNumber)) {
        this.accountNumberError = '모바일 번호는 숫자만 포함해야 합니다.'
      } else {
        this.accountNumberError = ''
      }
    },

    convertToLowercase() {
      this.registData.userid = this.registData.userid.toLowerCase()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
